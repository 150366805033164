
import { Vue, Options } from 'vue-class-component';
import { PageLoading, PdfViewer } from '@/lib/components';
import { EncounterService, PatientDocumentService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },

    encounterId: {
      type: String,
      required: true
    }
  },
  components: {
    PageLoading,
    PdfViewer
  }
})
export default class PatientLetter extends Vue {
  patientId!: string;
  encounterId!: string;
  encounterService = new EncounterService();
  patientDocumentService = new PatientDocumentService(this.patientId);
  pdf = '';
  notificationStore = useNotificationStore();

  async mounted() {
    try {
      await this.fetchPatientReview();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async fetchPatientReview() {
    const encounter = await this.encounterService.fetch(this.encounterId);

    // TODO: what if a review has multiple documents?
    if (encounter?.documents?.length > 0) {
      await this.getPdf(encounter.documents[encounter.documents.length - 1].id);
    }
  }

  async getPdf(id: string) {
    try {
      const response = await this.patientDocumentService.fetchBase64Pdf(id);

      this.pdf = `data:application/pdf;base64,${response}`;
    } catch (e) {
      this.$emit('close', e);
    }
  }
}
